
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import { fireGoogleTag, fireGoogleTagError } from '@/utils/google-tag-manager-helpers';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import XIcon from 'vue-feather-icons/icons/XIcon';
import TabInformation from '@/components/checkout/form/TabInformation.vue';
import InfoBanner from '@/components/shared/InfoBanner.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import Modal from '@/components/shared/Modal.vue';

const namespace: string = 'suites';

@Component({
	components: {
		ArrowLeftIcon,
		InfoBanner,
		LoadingSpinner,
		Modal,
		TabInformation,
		ValidationObserver,
		ValidationProvider,
		XIcon
	}
})
export default class SuiteSettings extends Vue {
	@Action('postToGetMinimalEventInfo', { namespace }) private postToGetMinimalEventInfo!: (suiteId: number) => void;
	@Action('suiteOpUpdateEventDaySettings', { namespace }) private suiteOpUpdateEventDaySettings!: (settings: EventDaySettings) => void;
	@Action('setCartFromCookies', { namespace: 'cart' }) private setCartFromCookies!: (orderingRequiresLogin?: boolean) => Promise<void>;
	@Getter('getUserEventSuite', { namespace }) private eventSuite!: EventSuite;
	@Getter('getFullName', { namespace: 'auth' }) private name!: string;
	@Getter('getSuiteOperator', { namespace: 'auth' }) private suiteOperator!: UserInfo;
	@Getter('getUser', { namespace: 'auth' }) private user!: UserInfo;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;

	private isLoading: boolean = false;
	private submitting: boolean = false;
	private mutableEventSuite: EventSuite | null = null;
	private paymentPreference: 'invoice' | 'charge' | null = null;
	private confirmModal = {
		open: false,
		header: '',
		body: '',
		onConfirm: () => {
			this.saveSettings();
		},
		onClose: () => false
	}

	$refs!: {observer: Validate}

	// Fetch and clone event suite
	private async created(): Promise<void> {
		try {
			this.isLoading = true;
			await this.postToGetMinimalEventInfo(+this.$route.query.eventSuite);
			this.mutableEventSuite = structuredClone(this.eventSuite);
			this.paymentPreference = this.mutableEventSuite.event_day_payment_preference;
			this.setCartFromCookies();
		} catch (error) {
			this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
			this.$router.go(-1);
		} finally {
			setTimeout(() => {
				this.isLoading = false;
			}, 500);
		}
	}

	/**
	 * Update tab limit on mutable event suite
	 *
	 * @param {number|null} tabLimit
	 * @return {void}
	 */
	private updateTabLimit(tabLimit: number | null): void {
		if (this.mutableEventSuite) {
			this.mutableEventSuite.tab_limit = tabLimit;
		}
	}
	/**
	 * Update payment preference on mutable event suite
	 *
	 * @return {void}
	 */
	private updatePaymentPreference(): void {
		if (this.mutableEventSuite) {
			this.mutableEventSuite.event_day_payment_preference = this.paymentPreference;
		}
	}

	/**
	 * Set modal content and actions on submit
	 *
	 * @return {Promise<void>}
	 */
	private async openConfirmModal(): Promise<void> {
		const isValid = await this.$refs.observer.validate();
		if (isValid) {
			// If payment method hasn't been set
			if (!this.eventSuite.event_day_payment_preference) {
				this.confirmModal = {
					...this.confirmModal,
					header: this.$t('operator.suite_settings.preference_modal.header'),
					body: this.$t('operator.suite_settings.preference_modal.body'),
					open: true,
					onClose: () => this.confirmModal.open = false
				};
			}
			// If tab limit has changed
			else if (this.eventSuite.tab_limit !== this.mutableEventSuite?.tab_limit) {
				this.confirmModal = {
					...this.confirmModal,
					header: this.$t('operator.suite_settings.tab_modal.header'),
					body: this.mutableEventSuite?.tab_limit ? this.$t('operator.suite_settings.tab_modal.body', { limit: this.mutableEventSuite?.tab_limit}) : this.$t('operator.suite_settings.tab_modal.remove_limit'),
					open: true,
					onClose: () => this.confirmModal.open = false
				};
			}
			// If nothing has changed
			else {
				this.redirectUser();
			}
		}
	}

	/**
	 * Handle saving suite settings on submit
	 *
	 * @return {void}
	 */
	private async saveSettings(): Promise<void> {
		const isValid = await this.$refs.observer.validate();
		this.confirmModal.open = false;
		try {
			this.submitting = true;
			if (this.mutableEventSuite && isValid) {
				await this.suiteOpUpdateEventDaySettings({
					eventSuite: this.mutableEventSuite,
					userId: this.suiteOperator.id,
					token: this.suiteOperator.token
				});
				this.handleAnalytics();
				this.redirectUser();
			}
		} catch(error) {
			this.$toasted.show(error.response?.data?.message, { type: 'error', position: 'top-center' }).goAway(5000);
			fireGoogleTagError({ name: 'suiteSettingsError', error: error.response?.data?.message });
		} finally {
			this.submitting = false;
		}
	}

	/**
	 * Check for missing profile info or missing payment method before redirecting.
	 *
	 * @return {void}
	 */
	private redirectUser(): void {
		if (!this.user.firstName || !this.user.lastName || !this.user.phoneNumber) {
			this.$router.push({ path: '/profile/user-information', query: this.$route.query }).catch(() => {});
		}
		else if (!this.user.paymentMethods.length) {
			this.$router.push({ path: '/profile/user-payment-methods', query: this.$route.query }).catch(() => {});
		}
		else {
			this.$router.push({ path: `/${this.restaurant.slug}`, query: this.$route.query }).catch(() => {});
		}
	}

	/**
	 * Send analytics events for updated suite settings
	 *
	 * @return {void}
	 */
	private handleAnalytics(): void {
		// If tab limit is set
		if (!this.eventSuite.tab_limit && this.mutableEventSuite?.tab_limit) {
			fireGoogleTag({ name: 'tabLimitSet' });
		}
		// If tab limit has changed
		else if (this.eventSuite.tab_limit !== this.mutableEventSuite?.tab_limit) {
			fireGoogleTag({ name: 'tabLimitChanged' });
		}

		// If payment preference has changed
		if (this.eventSuite.event_day_payment_preference !== this.mutableEventSuite?.event_day_payment_preference) {
			fireGoogleTag({
				name: 'eventDayPaymentPreference',
				detail: this.mutableEventSuite?.event_day_payment_preference || ''
			});
		}
	}
}
